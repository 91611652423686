@tailwind base;
@tailwind components;
@tailwind utilities;

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #e5e5e5;
}

html body {
  padding: 0;
  margin: 0;
  overflow-y: inherit;
}