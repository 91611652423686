@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  height: auto;
  width: auto;
}
